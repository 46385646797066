<template>
  <div>
    <titulo-seccion>
      <template slot="area-rutas">
        <router-link to="/">Inicio</router-link> / <span class="active">eventos</span>
      </template>
      <template slot="area-titulo">
        <h3 class="text-center">Eventos</h3>
      </template>
    </titulo-seccion>


    <div id="content-page" class="container">
      <b-row cols="12">
        <template v-for="item in items">
            <CardEvento :eventoData="item" :key="item.entrada_id" class="mb-4"></CardEvento>

        </template>
      </b-row>
      <div id="navegacion" class="row mt-5 mb-5"></div>
    </div>
  </div>
</template>

<script>
import axiosIns from "@/libs/axios";

import TituloSeccion from "../secciones/TituloSeccion.vue";
import CardEvento from "../eventos/CardEvento.vue";
import {


  BRow,


} from "bootstrap-vue";

export default {
  components: {
    BRow,
   
    TituloSeccion,
  
      CardEvento,

  },
  data() {
    return {
      textBusqueda: "",
      locations: [],
      items: [],
      itemspadres: [],
      itemsBusqueda: [],
      ubicacion: {
        position: { lat: -19.062117883514652, lng: -65.25878906250001 },
        visible: true,
        draggable: false,
      },
      selected: "0",
      zoom: 5,
      center: [-19.062117883514652, -65.25878906250001],


          
    };
  },
  metaInfo: {
      title: 'Ministerio Público',
      titleTemplate: '%s | Eventos',
      htmlAttrs: {
          lang: 'es-ES'
      },
  },
  mounted() {
    this.listar();
  },
  methods: {
    click: (e) => console.log("clusterclick", e),
    ready: (e) => console.log("ready", e),
    buscar() {
      if (this.textBusqueda != "") {
        const variable = `?busqueda=${this.textBusqueda}`;
        console.warn(variable);
        axiosIns
          .get(`/front/fiscalias${variable}`)
          .then((res) => {
            this.itemsBusqueda = res.data.data;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.itemsBusqueda = [];
      }
    },
    listar() {
      var url = "/front/eventos";
      axiosIns
        .get(url)
        .then((res) => {
          this.items = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

  },
};
</script>

<style>

</style>